const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;
export const createFeaturesWithWeightage = (): object => ({
	url: baseUrl+"/ipss/v1/featuresWithWeightage",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateFeaturesWithWeightage = (): object => ({
	url: baseUrl+"/ipss/v1/featuresWithWeightage",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getFeaturesConfig = (dellID: String) => {
	return {
		url: baseUrl+"/ipss/v1/featuresWithWeightage",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url: baseUrl + `/ipss/v1/featuresWithWeightage?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}