import React, { useEffect } from 'react';
import { PageTitle } from '../../Admin/styles';
import { DeviceTypeRankingTable } from "./DeviceTypeRankingTable";
import columns from './DataModel/DataModel';
import AddMeasuresButton from './Toolbar/AddMeasuresButton';
import { queryKeys } from '../../../../react-query/constants';
import { TableContextProvider } from './TableContext';
import { getDeviceTypeConfig } from './DeviceTypeRankingAPI';
import BackButton from '../BackButton';
import NextButton from '../NextButton';
import ProgressBar from '../Pbar';
import { ContentBox, ProgressBox } from '../IpssPageStyles';

const DeviceTypeRankingPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Asset Inventory | Device Type Ranking';
  }, []);

  return (
    <div className="dds__container-fluid dds__px-0">
      <PageTitle>PC Selector Criteria</PageTitle>
      <ContentBox>
        <ProgressBox>
          <ProgressBar />
        </ProgressBox>
        <TableContextProvider apiConfig={{ keyName: queryKeys.DeviceTypeRankingList, getDeviceTypeConfig }}>
          <DeviceTypeRankingTable AddMeasuresButton={AddMeasuresButton} columns={columns} />
        </TableContextProvider>
        <div style={{ float: 'right' }}>
          <BackButton url="/assetinventory/ipss/displayranking" />
          <NextButton url="/assetinventory/ipss/inventoryprocessorranking" isLast={false} />
        </div>
      </ContentBox>
    </div>
  );
};

export default DeviceTypeRankingPage;

