import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "react-alert";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { updateCreateAdminConfig } from "../../../API";

const useSaveData = (mode, handleClose, moduleId) => {
	const queryClient = useQueryClient();

	// Save success
	const saveSuccessHandler = () => {
		alert.show("Saved", {
			type: "success",
			timeout: 5000,
		});
		handleClose();
		queryClient.invalidateQueries([queryKeys.inventoryadminlist]);
	};

	console.log("moduleId", moduleId);

	//To Save data
	const {
		isLoading: isSaving,
		error: saveError,
		isError: isSaveError,
		mutate: saveAPI,
	} = useCustomMutation(updateCreateAdminConfig(mode, moduleId), {
		onSuccess: saveSuccessHandler,
		onError: error => {
			console.error(error.response.data.message);
			alert.show(
				error.response.data.message
					? error.response.data.message
					: "Unknown error occurred while saving, please try again later",
				{
					type: "error",
					timeout: 5000,
				}
			);
		},
	});

	const alert = useAlert();
	// Function to call save API with all the data
	const saveData = ({
		mode,
		regions,
		selectedItems,
		totalPermissionData,
		...props
	}) => {
		console.log(
			"selectedItems",
			selectedItems,
			regions,
			props,
			totalPermissionData
		);
		//let temp: any[] = [];
		let stockrooms: {
			country: string;
			stockroom: string;
		}[] = [];
		/* regions.forEach(region => {
			if (region.selected !== -1) {
				temp = [];
				console.log(region.country);
				region.stockrooms.forEach(stockroom => {
					if (stockroom.selected) {
						stockrooms.push({
							stockroom: stockroom.stockroom,
							country: stockroom.country,
						});
						temp.push(stockroom.name);
					}
				});
				console.log(temp);
			}
		}); */
		/* console.log("stockrooms", regions);
		debugger; */
		let locations: {
			locationName: string;
			subLocation: string[];
		}[] = [];
		regions.forEach(region => {
			if (region.selected !== -1) {
				let subLocations: string[] = [];
				region.stockrooms.forEach(stockroom => {
					if (stockroom.selected) {
						subLocations.push(stockroom.name);
					}
				});
				if (subLocations.length > 0) {
					locations.push({
						locationName: region.country,
						subLocation: subLocations,
					});
				}
			}
		});

		if (locations.length > 0) {
			// Todo: commenting temporary if else condition
			/* const body = {
				...props,
				stockrooms,
			}; */
			/* console.log("locations", locations);
			debugger; */

			// Todo: remove the temporary body for next release
			const temporarybody = {
				role: props.rolename === "admin" ? "ADMIN" : "SUPER_ADMIN",
				permissionId:
					props.rolename === "admin" ? selectedItems : totalPermissionData,
				userId: props.badgeId,
				groupType: "",
				location: locations,
				group: [
					{
						groupId: 0,
						name: "string",
					},
				],
			};
			/* console.log(body); */
			/* console.log(locations); */
			saveAPI(temporarybody);
		} else {
			alert.show("Please select at least one stockroom to proceed", {
				type: "error",
				timeout: 5000,
			});
		}
	};

	return { isSaving, saveData };
};

export default useSaveData;
