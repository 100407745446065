import styled from 'styled-components';

export const TextSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: white;
  padding: 1rem;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

interface DisabledProps {
  disabled: boolean;
}

export const FileInputLabel = styled.label<DisabledProps>`
  background-color: "white";
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0.25rem;
  height: 2rem;
  color: #0672cb;
  border: 1.1px solid #0672cb;
  border-radius: 2px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  font-size: 14px;
  &:hover {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
  }

  &:active {
    background-color: ${props => (props.disabled ? '#f0f0f0' : '#e0e0e0')};
    transition: background-color 0.1s ease;

  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const SubmitButton = styled.button<DisabledProps>`
  background-color: ${props => (props.disabled ? '#a3c9f1' : '#0672cb')};
  height: 2rem;
  color: white;
  border: none;
  text-align: center;
  border-radius: 2px;
  font-size: 14px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${props => (props.disabled ? '#a3c9f1' : '#00468b')};
    transition: background-color 0.3s ease; /* Added transition */
  }
`;

export const Message = styled.p`
  width: 20rem;
  height: 2rem;
  color: gray;
  font-size: 14px; 
`;

export const SuccessMessage = styled.p`
  color: #49cc90;
  font-size: 12px; /* Increased font size */
  margin: 0;
  padding: 0;
`;

export const ProgressBarContainer = styled.div`
  width: 95%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.25rem;
`;

interface ProgressProps {
  width: number;
}

export const Progress = styled.div<ProgressProps>`
  height: 0.25rem;
  background-color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: ${props => props.width}%;
  transition: width 0.5s linear;
`;

export const Dustbin= styled.div<DisabledProps>`
  color: gray;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${props => (props.disabled ? "gray" : "#b33131")}; 
  }
`;
