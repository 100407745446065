import React from 'react';
import { useEffect } from 'react';
import { PageTitle } from '../Admin/styles';
import FileUpload from './FileUpload';
import { ContentBoxFile } from '../IPSSMeasures/IpssPageStyles';

const BulkAssetRecommendationPage: React.FC = () => {
    useEffect(() => {
        document.title = 'Asset Inventory | Catalog';
    }, []);
    return (
        <div className="dds__container-fluid dds__px-0">
            <PageTitle>Bulk PC Selector Recommendation</PageTitle>
            <ContentBoxFile>
                <FileUpload />
            </ContentBoxFile>
        </div >
    );
};

export default BulkAssetRecommendationPage;