import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../../hooks/useAuth';
import axios from 'axios';
import { useUserInfo } from '../../../store/auth/UserInfoContext';
import {
  FileUploadContainer,
  FileInputLabel,
  FileInput,
  SubmitButton,
  Message,
  SuccessMessage,
  ProgressBarContainer,
  Progress,
  Dustbin,
} from './StyleComponents';
import { DDSIcon } from '@dds/react';
import Tooltip from './Tooltip';

const baseUrl = process.env.REACT_APP_BUIKRECOMMENDATION_URL;


const FileUpload: React.FC = () => {
  const [newfile, setFile] = useState<File | null>(null);
  const [originalFileName, setOriginalFileName] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Upload');
  const [statusCode, setStatusCode] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  // const bulkWrapperApi = "https://sec-inbound.testapis.dell.com/trackitv2/ipss/v1/bulkRecommend"; //npnprod layer 7 url change to prod url
  const bulkWrapperApi = baseUrl + "/ipss/v1/bulkRecommend"; //prod url
  const [Myuuid, setMyuuid] = useState<string>('');

  const user = useUserInfo();
  const auth = useAuth();

  useEffect(() => {
    if (newfile) {
      setButtonText('Upload');
    } else if (statusCode === '200') {
      setButtonText('Uploaded');
    }
  }, [newfile]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      const fileType = selectedFile.type;
      const validTypes = ['text/csv'];

      if (!validTypes.includes(fileType)) {
        alert('Only .csv files are allowed!');
        return;
      }

      const uuid = uuidv4();
      setMyuuid(uuid);
      const fileNameParts = selectedFile.name.split('.');
      const newFileName = `${fileNameParts[0]}.${fileNameParts[1]}`;
      const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });
      setFile(newFile);
      setOriginalFileName(selectedFile.name);
      setButtonText('Upload');
      setShowSuccessMessage(false);

      event.target.value = '';
    }
  };

  const handleRemoveFile = () => {
    if (isSubmitting === false) {
      setFile(null);
      setOriginalFileName('');
      setUploadProgress(0);
      const fileInput = document.getElementById('file-upload') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  };

  const handleSubmit = async () => {
    if (!newfile) {
      alert('Please select a file first!');
      return;
    }

    setIsSubmitting(true);

    const apikey = process.env.REACT_APP_L7_APIKEY;
    const token = await auth.getAccessToken();

    let formData = new FormData();
    formData.append('folder', 'IPSS_bulk_recom');
    formData.append('file', newfile);

    try {
      const response = await axios.post(bulkWrapperApi, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          jwt_token: `${token!.replace(/\n|\r/g, "")}`,
          apikey: apikey,
        },
        onUploadProgress: (progressEvent) => {
          const progressEventTotal = progressEvent.total;
          const progress = progressEventTotal ? Math.round((progressEvent.loaded * 100) / progressEventTotal) : 0;
          setUploadProgress(progress);
        }
      });
      if (response.data.code == 200) {
        setButtonText('Uploaded');
        setStatusCode("200");
        setShowSuccessMessage(true);
        setTimeout(() => {
          resetForm(false);
        }, 500);
      } else {
        alert('File upload failed! please try again after checking file details.');
        resetForm(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      alert('Error uploading file!');
      resetForm(true);
    }
  };

  const resetForm = (resetSuccessMessage = true) => {
    setUploadProgress(0);
    setFile(null);
    setOriginalFileName('');
    setButtonText('Upload');
    setStatusCode('');
    setIsSubmitting(false);
    if (resetSuccessMessage) {
      setShowSuccessMessage(false);
    }
  };

  return (
    <FileUploadContainer>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "left", border: '1px solid #ddd', borderRadius: '5px', padding: "1rem", paddingTop: "0.5rem" }}>
        <p style={{ fontSize: "14px" }} >Upload Devices to generate bulk recommendations</p>
        <p style={{ fontSize: "12px", color: "gray" }}>
          Download File template:
          <a href="/Sample_format.csv" download="Sample_format.csv" target='_blank'>
            <DDSIcon
              name="download"
              style={{
                fontSize: "0.75rem",
                color: "#0672cb",
                marginLeft: "0.25rem",
                marginBottom: "0.25rem",
                transform: "scale(1.1)",
                transition: "transform 0.3s ease"
              }}
              onMouseEnter={(e) => (e.currentTarget as HTMLElement).style.transform = "scale(1.2)"}
              onMouseLeave={(e) => (e.currentTarget as HTMLElement).style.transform = "scale(1.1)"}
            />
          </a>
        </p>
        <p style={{ fontSize: "12px", color: "gray", display: "flex", flexDirection: "row", alignItems: "center" }}>
          Accepted Formats: .csv
          <Tooltip text="Note: The file should contain only 1 column from : serial_number, assigned_to, email, u_dell_employee_id">
            <DDSIcon
              name="alert-info-cir"
              style={{
                fontSize: "0.75rem",
                color: "#0672cb",
                marginLeft: "0.25rem",
                marginRight: "0.5rem"
              }}
            />
          </Tooltip>
        </p>
        <FileInputLabel htmlFor="file-upload" disabled={!!newfile} style={{ marginBottom: "0.5rem" }} >
          <p style={{ fontWeight: "bold", color: "#0672cb" }}>
            <DDSIcon name="upload" style={{ fontSize: "1rem", color: "#0672cb", marginRight: "1rem" }} />
            Browse Files
          </p>
        </FileInputLabel>
        <FileInput id="file-upload" type="file" accept=".csv" onChange={handleFileChange} disabled={!!newfile} />
        {newfile && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', borderRadius: '2px', marginBottom: "0.5rem" }}>
          {newfile && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: "20rem", height: "2rem", textAlign: 'left', marginLeft: "1rem", paddingTop: "1rem" }}>
            {newfile && <Message>Selected file: {originalFileName}</Message>}
            <Dustbin disabled={isSubmitting}>
              {newfile && <div className='dds__icon--close-x' onClick={handleRemoveFile} style={{ fontSize: "1rem", marginBottom: "1rem", marginRight: "1rem" }}></div>}
            </Dustbin>
          </div>}
          {uploadProgress > 0 && (
            <ProgressBarContainer>
              <Progress width={uploadProgress}>
              </Progress>
            </ProgressBarContainer>
          )}
        </div>}
        <SubmitButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} onClick={handleSubmit} disabled={!newfile || isSubmitting}>
          {buttonText}
        </SubmitButton>
        {showSuccessMessage && (
          <p style={{ textAlign: 'left', marginTop: '1rem' }}>
            <SuccessMessage>An email will be sent to you once file has been processed.</SuccessMessage>
          </p>
        )}
      </div>
    </FileUploadContainer>
  );
};

export default FileUpload;