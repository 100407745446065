
const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;


export const createCatalog = (): object => ({
	url: baseUrl + "/ipss/v1/inventory",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateCatalog = (): object => ({
	url: baseUrl + "/ipss/v1/inventory",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getCatalog = () => {
	return {
		url: baseUrl + "/ipss/v1/inventory",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteCatalog = (id: number) => {
	return {
		url: baseUrl +`/ipss/v1/inventory?id=${id}`, 
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}