import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../../ErrorStates";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import { useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";

export const TableContext = createContext<any>(null);

// Define TableContextProvider component
export const TableContextProvider = ({ apiConfig, children }) => {
    const queryClient = useQueryClient();
    const user = useUserInfo();

    const { isLoading, error: fetchError, data: telemetryData, refetch } = useCustomQuery(
        [apiConfig.keyName],
        apiConfig.getDisplayConfig(user.getUserInfo().badgeId), // Assuming this fetches telemetry measures
        {
            select: (data) => {
                return data.data; // Assuming telemetry measures are in data.result
            }
        }
    );

    const [error, setError] = useState<string>();
    const handleError = useErrorHandler(); // Assuming this is a custom hook to handle errors
    const [mappedData, setMappedData] = useState<any[]>([]);
    // Remove filteredData state and filterData function as they are no longer needed

    useEffect(() => {
        // refetch()
        if (telemetryData) {

            // Map sortedData to match the expected structure
            const mappedResults = telemetryData.map((item) => {
                const mappedItem: any = {
                    display: item.display,
                    ranking: item.ranking,
                    id: item.id,
                };

                return mappedItem;
            });


            setMappedData(mappedResults);
            console.log("Telemetry Data loaded:", telemetryData);
        }
    }, [telemetryData]);

    useEffect(() => {
        if (fetchError) {
            console.log("Error on component", fetchError);
            if (fetchError instanceof APIRequestFailedError) {
                if (fetchError.statusCode === "201") {
                    setError("No Record Found");
                } else if (fetchError.statusCode === "204") {
                    setError(fetchError.message);
                } else {
                    handleError(fetchError);
                }
            } else if (fetchError instanceof AuthError) {
                handleError(fetchError);
            } else {
                setError(
                    "Data is not loading at the moment. Please check your connection and try again."
                );
            }
        }
    }, [fetchError, handleError]);

    const handleRefetch = () => {
        refetch(); // Trigger manual refetch of data
    };

    const deleteSuccessHandler = (data) => {
        queryClient.invalidateQueries([apiConfig.keyName]); // Invalidate and refetch data
    };

    // Render children when data is loaded and no error
    return <TableContext.Provider value={{ allData: mappedData, refetchData: handleRefetch, setMappedData }}>
        {(isLoading || error) && <div className="dds__mx-auto dds__text-center" style={{
            marginTop: "calc(100px + 1.5rem)"
        }}>
            {isLoading &&
                <LoadingSpinner label="Fetching Display Ranking" />
            }
            {error && <>{error}</>}

        </div>}
        {!isLoading && !error && children}
    </TableContext.Provider>
};
