const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

export const createInventoryProcessorRanking = (): object => ({
	url: baseUrl + `/ipss/v1/inventoryProcessorRanking`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateInventoryProcessorRanking = (): object => ({
	url: baseUrl + `/ipss/v1/inventoryProcessorRanking`,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getMeasuresConfig = () => {
	return {
		url: baseUrl + `/ipss/v1/inventoryProcessorRanking`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url:baseUrl + `/ipss/v1/inventoryProcessorRanking?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}