const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

export const createModelConfigs = (): object => ({
	url: baseUrl + "/ipss/v1/modelConfigs",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateModelConfigs = (): object => ({
	url: baseUrl + "/ipss/v1/modelConfigs",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getModelConfig = (dellID: string) => {
	return {
		url: baseUrl + "/ipss/v1/modelConfigs",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url: baseUrl + `/ipss/v1/modelConfigs?id=${id}`,
		method: "DELETE", //using pcf url for now for CRUD
		headers: {
			"Content-Type": "application/json",
		},
	}
}