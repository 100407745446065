import AdminPage from "../../components/AssetInventory/Admin/AdminPage";
import ManageUsersPage from "../../components/AssetInventory/ManageUsers/ManageUsersPage/ManageUsersPage";
import MyAssetsPage from "../../components/AssetInventory/MyAssets/MyAssetsPage";
import OrgAssetsPage from "../../components/AssetInventory/Org/OrgAssetsPage";
import AssetInventoryLayout from "../../Layouts/AssetInventoryLayout";
import LeaderBoardPage from "../../components/AssetInventory/LeaderBoard/AssetInventoryLeaderBoardPage";
import UtilizationRulesPage from "../../components/AssetInventory/IPSSMeasures/UtilizationRules/UtilizationRulesPage";
import CatalogPage from "../../components/AssetInventory/IPSSMeasures/Catalog/CatalogPage";
import PersonaJobFamilyMappingPage from "../../components/AssetInventory/IPSSMeasures/PersonaJobFamilyMapping/PersonaJobFamilyMappingPage";
import FeaturesWithWeightagePage from "../../components/AssetInventory/IPSSMeasures/FeaturesWithWeightage/FeaturesWithWeightagePage";
import InventoryModelTrainWeightagePage from "../../components/AssetInventory/IPSSMeasures/InventoryModelTrainWeightage/InventoryModelTrainWeightagePage";
import ModelConfigsPage from "../../components/AssetInventory/IPSSMeasures/ModelConfigs/ModelConfigsPage";
import GraphicsProcessorRankingPage from "../../components/AssetInventory/IPSSMeasures/GraphicsProcessorRanking/GraphicsProcessorRankingPage";
import AssetRecommendation from "../../components/AssetInventory/AssetRecommendation/AssetRecommendationPage";
import routes from "./routers";
import RunModelPage from "../../components/AssetInventory/IPSSMeasures/RunModelPage";
import DisplayRankingPage from "../../components/AssetInventory/IPSSMeasures/DisplayRanking/DisplayRankingPage";
import DeviceTypeRankingPage from "../../components/AssetInventory/IPSSMeasures/DeviceTypeRanking/DeviceTypeRankingPage";
import InventoryProcessorRankingPage from "../../components/AssetInventory/IPSSMeasures/InventoryProcessorRanking/InventoryProcessorRankingPage";
import BulkAssetRecommendationPage from "../../components/AssetInventory/BulkAssetRecommendation/BulkAssetRecommendationPage";

const inventoryRoutes = [
	{
		path: "/assetinventory",
		exact: true,
		layout: AssetInventoryLayout,
		name: "My Assets",
		component: MyAssetsPage,
	},
	{
		path: "/assetinventory/admin",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Admin",
		component: AdminPage,
	},
	{
		path: "/assetinventory/myorg",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Org Assets",
		component: OrgAssetsPage,
	},
	{
		path: "/assetinventory/leaderboard",
		exact: true,
		layout: AssetInventoryLayout,
		name: "LeaderBoard - L3",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L4",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L5",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L6",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager/:l6_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L7",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager/:l6_manager/:l7_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L8",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/manageusers",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Manage Users",
		component: ManageUsersPage,
	},
	{
		path: "/assetinventory/ipss/utilizationrules",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Utilization Rules",
		component: UtilizationRulesPage,
	},
	{
		path: "/assetinventory/assetrecommendation/assetrecommendationutilisation",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Asset Recommendation",
		component: AssetRecommendation,
	},
	{
		path: "/assetinventory/ipss/runmodelpage",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Run Model",
		component: RunModelPage,
	},
	{
		path: "/assetinventory/ipss/catalog",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Catalog",
		component: CatalogPage,
	},
	{
		path: "/assetinventory/ipss/personajobfamilymapping",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Persona Job Family Mapping",
		component: PersonaJobFamilyMappingPage,
	},
	{
		path: "/assetinventory/ipss/featureswithweightage",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Features With Weightage",
		component: FeaturesWithWeightagePage,
	},
	{
		path: "/assetinventory/ipss/inventorymodeltrainweightage",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Catalog Model Train Weightage",
		component: InventoryModelTrainWeightagePage,
	},
	{
		path: "/assetinventory/ipss/modelconfigs",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Model Configs",
		component: ModelConfigsPage,
	},
	{
		path: "/assetinventory/ipss/graphicsprocessorranking",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Graphics Ranking",
		component: GraphicsProcessorRankingPage,
	},
	{
		path: "/assetinventory/ipss/inventoryprocessorranking",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Processor Ranking",
		component: InventoryProcessorRankingPage,
	},
	{
		path: "/assetinventory/ipss/displayranking",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Display Ranking",
		component: DisplayRankingPage,
	},
	{
		path: "/assetinventory/ipss/devicetyperanking",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Device Type Ranking",
		component: DeviceTypeRankingPage,
	},
	{
		path: '/assetinventory/ipss/bulkassetrecommendation',
		exact: true,
		layout: AssetInventoryLayout,
		name: "Bulk PC Selector Recommendation",
		component: BulkAssetRecommendationPage,
	},
	
];
export default inventoryRoutes;
