const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

export const createPersonaJobFamilyMapping = (): object => ({
	url: baseUrl + "/ipss/v1/personaJobFamilyMapping",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});

export const updatePersonaJobFamilyMapping = (): object => ({
	url: baseUrl + "/ipss/v1/personaJobFamilyMapping",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getPersonaJobFamilyMapping = (dellID: string) => {
	return {
		url: baseUrl + "/ipss/v1/personaJobFamilyMapping",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeletePersonaJobFamilyMapping = (id: number) => {
	return {
		url: baseUrl + `/ipss/v1/personaJobFamilyMapping?id=${id}`, 
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}