const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

export const createDisplayRanking = (): object => ({
	url: baseUrl + `/ipss/v1/displayRanking`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateDisplayRanking = (): object => ({
	url: baseUrl + `/ipss/v1/displayRanking`,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getDisplayConfig = () => {
	return {
		url: baseUrl + `/ipss/v1/displayRanking`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteDisplayQueryConfig = (id: number) => {
	return {
		url: baseUrl + `/ipss/v1/displayRanking?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}