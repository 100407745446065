import { objectToQuery } from "../../utils/querycreator";

const baseUrl = process.env.REACT_APP_INVENTORY_BASE_URL;
const newBaseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;
export const myAssetsConfig = () => {
	return {
		url: baseUrl + "/api/v1/myassets",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

const decodeHashUriComponent = (text: string) => {
	const decodedUriComponent = decodeURIComponent(text);
	const b64decoding = window.atob(decodedUriComponent);
	return b64decoding;
};
export const getLeaderBoardConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	urlParams?: {
		l3_manager?: string;
		l4_manager?: string;
		l5_manager?: string;
		l6_manager?: string;
		l7_manager?: string;
	}
) => {
	let endpoint = baseUrl + "/api/FetchLeaderAssetDetails";
	let url = new URL(window.location.href);
	if (
		urlParams?.l3_manager ||
		urlParams?.l4_manager ||
		urlParams?.l5_manager ||
		urlParams?.l6_manager ||
		urlParams?.l7_manager
	) {
		if (url.searchParams.get("team") === "true") {
			endpoint = baseUrl + "/api/FetchTeamDetails";
		} else endpoint = baseUrl + "/api/FetchSubLeaderAssetDetails";
		if (urlParams?.l7_manager) {
			params["level"] = "L7";
			params["leaderBadgeID"] = decodeHashUriComponent(
				urlParams.l7_manager
			).split("###")[0];
		} else if (urlParams?.l6_manager) {
			params["level"] = "L6";
			params["leaderBadgeID"] = decodeHashUriComponent(
				urlParams.l6_manager
			).split("###")[0];
		} else if (urlParams?.l5_manager) {
			params["level"] = "L5";
			// params['sublevel'] = 'l6'
			params["leaderBadgeID"] = decodeHashUriComponent(
				urlParams.l5_manager
			).split("###")[0];
		} else if (urlParams?.l4_manager) {
			params["level"] = "L4";
			// params['sublevel'] = 'l5'
			params["leaderBadgeID"] = decodeHashUriComponent(
				urlParams.l4_manager
			).split("###")[0];
		} else if (urlParams?.l3_manager) {
			params["level"] = "L3";
			// params['sublevel'] = 'l4'
			params["leaderBadgeID"] = decodeHashUriComponent(
				urlParams.l3_manager
			).split("###")[0];
		}
	}
	return {
		url: endpoint + "?" + objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const overallAssetDetailConfig = () => {
	return {
		url: baseUrl + "/api/v1/FetchOverallAssetDetails",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const leaderProductDetailConfig = (
	params: {
		page: number;
		size: number;
		search?: string;
		q?: string;
		exportCsv?: boolean;
	},
	urlParams?: {
		leaderBadgeID: string;
		level: string;
	}
) => {
	return {
		url:
			baseUrl +
			"/api/v1/FetchLeaderProductDetails?" +
			objectToQuery(urlParams) +
			"&" +
			objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const leaderStockDetailConfig = (
	params: {
		page: number;
		size: number;
		q?: string;
		sort?: string;
		exportCsv?: boolean;
	},
	urlParams?: {
		leaderBadgeID: string;
		level: string;
	}
) => {
	return {
		url:
			baseUrl +
			"/api/FetchLeaderStockroomDetails?" +
			objectToQuery(urlParams) +
			"&" +
			objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const leaderAssetDetailConfig = (
	params: {
		page: number;
		size: number;
		q?: string;
		sort?: string;
		exportCsv?: boolean;
	},
	urlParams?: {
		leaderBadgeID: string;
		level: string;
	}
) => {
	return {
		url:
			baseUrl +
			"/api/FetchLeaderTotalAssetDetails?" +
			objectToQuery(urlParams) +
			"&" +
			objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const orgAssetsCountConfig = () => {
	return {
		url: baseUrl + "/api/v1/OrgAssetsCount",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const orgAssetsConfig = (params: { category: number }) => {
	return {
		url: baseUrl + "/api/v1/OrgAssets?" + objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const stockroomListConfig = (params: { isAllStockrooms?: boolean }) => {
	return {
		//url: baseUrl + "/api/v1/stockrooms?" + objectToQuery(params),
		url: newBaseUrl + "/asset/v2/stockrooms?" + objectToQuery(params),
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assetInventoryRoleConfig = () => {
	return {
		url: baseUrl + "/api/v1/AssetInventoryRole",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const adminAssetCountConfig = () => {
	return {
		url: baseUrl + "/api/v1/AdminAssetsCount",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const adminTableConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
		sort: string;
	},
	filters: {
		countries: string[];
		stockrooms: string[];
		assetCondition: string;
		fundedBy: string;
		status: string;
		sub_status_list: string[];
	}
) => {
	let endpoint = "/api/v1/AdminAssetDetails";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: filters,
	};
};

export const assetConditionListConfig = () => {
	return {
		url: baseUrl + "/api/v1/AssetCondition",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assetStatusListConfig = () => {
	return {
		url: baseUrl + "/api/v1/AssetStatus",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assetFundedListConfig = () => {
	return {
		url: baseUrl + "/api/v1/AssetFundedBy",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const getAdminsConfig = moduleId => {
	return {
		//url: baseUrl + "/api/v1/admins",
		url: newBaseUrl + `/user/admin/v2/moduleId/list?moduleId=${moduleId}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const updateCreateAdminConfig = (mode: "new" | "edit", moduleId) => {
	//let endpoint = baseUrl;
	let endpoint = newBaseUrl;
	let method = "POST";
	if (mode === "edit") {
		//endpoint += "/api/v1/updateAdmin";
		endpoint += `/user/admin/v2/moduleId/update?moduleId=${moduleId}`;
		method = "PUT";
	} else endpoint += `/user/admin/v2/moduleId/create?moduleId=${moduleId}`;
	return {
		url: endpoint,
		method,
		headers: {
			"Content-Type": "application/json",
		},
		// body: body
	};
};

export const deleteUserConfig = (id: string, moduleId: any, userId: any) => {
	// id:String
	return {
		//url: baseUrl + "/api/v1/deleteAdmin/id?" + objectToQuery({ id: id }),
		url:
			newBaseUrl +
			`/user/admin/v2/moduleId/delete?moduleId=${moduleId}&userId=${userId}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assetInventoryMenuListConfig = moduleID => {
	return {
		url: newBaseUrl + `/user/admin/v2/menu/list?moduleId=${moduleID}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const assetInventoryPermissionList = moduleId => {
	return {
		url: newBaseUrl + `/user/admin/v2/permission/list?moduleId=${moduleId}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const orgAutofillUserConfig = (q: string) => {
	return {
		url: baseUrl + `/api/v1/searchUser?search=${q}`,
		method: "GET",
		Headers: {
			"Content-Type": "application/json",
		},
	};
};
