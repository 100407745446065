import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, unauth } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import User from './User';
import { useUnauthAPI } from './useUnauthAPI';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';

const UnauthCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isLoading: isUserLoading, error: userError, data: userData } = useUnauthAPI();
		const { getUserRole } = useUserInfo();
		const role = getUserRole(unauth);
		const { isOrg } = useDashboardContext();

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[unauth]) {
					setMessage(metaData.data[unauth]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (!isOrg && !isUserLoading && !userError) {
				let temp = "No Softwares to be removed"
				if (userData.length > 0)
					temp = userData.length + ' Software removals: View Details';
				setMessage(temp);
				// Add logic to update query data with message 
			}
		}, [isUserLoading, userData, userError, isOrg]);

		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "https://unauthsoft.dell.com/",
					external: true,
					ariaLabel: "Open " + unauth + " dashboard in a new tab" // if external is true
				}
			]
		}]
		const triggerID = createTrigger(unauth)


		return <Card
			themeColor={cardsTheme[unauth].themeColor}
			name={unauth}
			ddsIconClass={cardsTheme[unauth].ddsIconClass}
			isFav={favouriteStatus}
			idName={unauth}
			description={description}
			isLoading={(message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={unauth}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={unauth} tabsData={tabDetails} salt={unauth} />
			</DDSDrawer>
		</Card>

	}

export default UnauthCard