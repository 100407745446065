import ReactTooltip from "react-tooltip";
import DefaultCell from "../../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import FloatingPillColumn from "./FloatingPillColumn";

const columns: any[] = [
	{
		Header: "Employee Name",
		//accessor: "admname",
		accessor: "fullName",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Employee email",
		accessor: "email",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Country",
		//accessor: "country",
		accessor: "locationName",
		disableFilters: true,
		disableSortBy: true,
		width: 110,
		Cell: FloatingPillColumn,
		show: true
	},
	{
		Header: "Stockroom",
		//accessor: "stockrooms",
		accessor: "location",
		disableFilters: true,
		disableSortBy: true,
		width: 140,
		Cell: FloatingPillColumn,
		show: true
	},
	{
		Header: "Role",
		//accessor: "rolename",
		accessor: "roleName",
		disableFilters: true,
		disableSortBy: false,
		width: 40,
		Cell: ({ value, color = "var(--blue-800)", ...props }) => {
			return <div style={{
				display: 'flex',
				alignItems: 'center',
				height: '100%'
			}}>
				<p
					style={{ display: "inline", color: color, wordBreak: 'break-word', textTransform: "capitalize" }}
					data-for={`${props.column.id}_${props.row.id}_tip`}
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id={`${props.column.id}_${props.row.id}_tip`} />
			</div>
		}
	},
	{
		Header: "Permission",
		accessor: "permissionName",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value, color = "var(--blue-800)", ...props }) => {
			return (
				<div style={{
					display: 'flex',
					alignItems: 'center',
					height: '100%'
				}}>
					{value.map((item, index) => (
						<div key={item.id} style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
							<p
								style={{ display: "inline", color: "var(--blue-800)", wordBreak: 'break-word', textTransform: "capitalize" }}
								data-for={`${item.id}_tip`}
								data-tip={item.name}
							>
								{item.name}{index < value.length - 1 ? <span>,&nbsp;</span> : ''}
							</p>
							<ReactTooltip id={`${item.id}_tip`} />
						</div>
					))}
				</div>
			);
		}
	},
	{
		Header: "Status",
		accessor: "status",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: DefaultCell,
		show: false
	},
	{
		Header: "Requested By",
		accessor: "requestedBy",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
		show: false
	},
	{
		Header: "Action",
		accessor: "action",
		disableFilters: true,
		disableSortBy: true,
		width: 70,
		Cell: ActionColumn,
	},
	{
		Header: "accesstype",
		accessor: "accesstype",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "roleid",
		accessor: "roleid",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "ntid",
		accessor: "ntid",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},



];
export default columns;