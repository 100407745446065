import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {visible && (
        <div style={tooltipContainerStyle}>
          <div style={tooltipStyle}>
            {text}
            <div style={arrowStyle}></div>
          </div>
        </div>
      )}
    </div>
  );
};

const tooltipContainerStyle = {
  position: 'absolute',
  top: '30%',
  left: '100%', // Position to the right of the element
  transform: 'translateY(-50%)',
  textAlign: 'center',
  zIndex: 1,
  visibility: 'visible',
  opacity: 0.8,
};

const tooltipStyle = {
  backgroundColor: 'rgba(20, 20, 20, 0.8)', // Dark grey, almost black background with the same opacity
  color: '#fff',
  borderRadius: '0.25rem',
  fontSize: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  minWidth: '20rem', // Increased minimum width
  whiteSpace: 'normal', // Allow text to wrap
  backdropFilter: 'blur(2rem)', // Increased blur for more glassy effect
  border: '1px solid black', // Sleek black border
  textShadow: '1px 1px 2px black', // Add this line for text outline
  position: 'relative',
  height:"3rem",
};

const arrowStyle = {
  width: 0,
  height: 0,
  borderRight: '6px solid rgba(20, 20, 20, 0.8)', // Match the tooltip background
  borderTop: '6px solid transparent',
  borderBottom: '6px solid transparent',
  position: 'absolute',
  top: '50%',
  left: '-6px', // Position the arrow correctly
  transform: 'translateY(-50%)',
};

export default Tooltip;