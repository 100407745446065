const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

export const createGraphicsProcessorRanking = (): object => ({
	url: baseUrl + `/ipss/v1/graphicsRanking`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateGraphicsProcessorRanking = (): object => ({
	url: baseUrl + `/ipss/v1/graphicsRanking`,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getMeasuresConfig = () => {
	return {
		url: baseUrl + `/ipss/v1/graphicsRanking`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url: baseUrl +  `/ipss/v1/graphicsRanking?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}