import { createContext, useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import { stockroomListConfig } from "../../API";
// import { dummyData } from "./dummyData";

const RegionContext = createContext<any>(null);

export type stockroomObj = {
	name: string,
	selected: boolean,
	country: string,
	stockroom: string
}[]

export type regionData = {
	country: string,
	selected: number,
	stockrooms: stockroomObj
}[]

export const RegionContextProvider = ({ children }) => {
	const alert = useAlert();
	// To keep track of all the regions and stockrooms - selected or not
	const [regions, setRegions] = useState<regionData>([])
	// To fetch region(Country-stockroom) data
	// const { isLoading: countriesLoading, error: countriesError, sendRequest: fetchCountries } = useAxios();
	const { isLoading: countriesLoading, error: countriesError, data: regionData } = useCustomQuery([queryKeys.inventoryregions], stockroomListConfig({
		isAllStockrooms: true
	}), {
		staleTime: Infinity,
		cacheTime: Infinity,
		select: (dataObj) => {
			// commenting the new code
			let temp: regionData = [];
			let data = dataObj.data;


			data.forEach(element => {
				let stockroomObj: stockroomObj = []
				let country = element.locationName;
				element.subLocation.forEach((e) => {
					stockroomObj.push({
						name: e.trim(),
						selected: false,
						country: country,
						stockroom: e,
					})
				})
				temp.push({
					country: country,
					selected: -1,
					stockrooms: stockroomObj
				})

			});
			console.log(temp)
			return temp;

			//enabling the old code
			/* let temp: regionData = [];
			let data = dataObj.stockrooms;


			data.forEach(element => {
				let stockroomObj: stockroomObj = []
				let country = element.countryName;
				element.stockRooms.forEach((e) => {
					stockroomObj.push({
						name: e.name.trim(),
						selected: false,
						country: country,
						stockroom: e.name,
					})
				})
				temp.push({
					country: country,
					selected: -1,
					stockrooms: stockroomObj
				})

			});
			console.log(temp)
			return temp; */
		}
	});

	// Fetch initial region data and clean event listener on component unmount
	useEffect(() => {
		if (regionData && regions.length === 0) {
			setRegions(regionData)
		}
	}, [regionData])

	const resetRegion = () => {
		let tempRegions = regions.map((element) => {
			let temp: stockroomObj = []
			temp = element.stockrooms.map((stockroom) => {
				return {
					...stockroom,
					selected: false
				}
			})
			return {
				...element,
				selected: -1,
				stockrooms: temp,
			}
		})
		setRegions(tempRegions)
	}

	// Region data error handler
	useEffect(() => {
		if (countriesError)
			alert.show("Unable to fetch region data. Please try again later", {
				type: "error",
				timeout: 5000
			})
	}, [countriesError])


	return <RegionContext.Provider value={{ regions, setRegions, resetRegion }}>
		{countriesLoading &&
			<div className="dds__mx-auto dds__text-center" style={{
				marginTop: "calc(100px + 1.5rem)"
			}}>
				<LoadingSpinner label="Fetching Regions" />
				{/* <h6 className="dds_h6">Fetching region data</h6> */}
			</div>}
		{!countriesLoading && children}
	</RegionContext.Provider>
}

export const useRegions = () => {
	const context = useContext(RegionContext)
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}