const baseUrl = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PageTitle } from '../Admin/styles';
import { useAuth } from '../../../hooks/useAuth';
import ImageFetcher from './ImageFetcher';

import Tooltip from './Tooltip';
import Legend from './Legend';

interface UserInfo {
    "Full Name": string;
    "Email": string;
    "Job Family": string;
    "TMX Persona": string;
    "GTM Persona": string;
    "Current Device ": string;
    "Manager": string;
    "Score": string;
}

interface CurrentUtil {
    "avg_cpu_perc": string;
    "max_cpu_perc": string;
    "max_gpu_usage_perc": string;
    "avg_memory_perc": string;
    "active_time_hrs": string;
    "cpu_impact_time_hrs": string;
    "memory_impact_time_hrs": string;
    "available_storage": string;
}


interface CurrentSysInfo {
    [key: string]: string;
}

interface Recommendation {
    recommend_no: number;
    persona: string;
    user_type: string;
    device_type: string;
    device_name: string;
    processor: string;
    memory_in_gb: number;
    storage: string;
    graphics: string;
    display: string;
    score: string;
}
interface TelemetryItem {
    telemetryMeasures: string;
    subMeasure: string;
    lowThreshold: string;
    mediumThreshold: string;
    highThreshold: string;
    id: number;
}

const keyMappingUtil: Record<string, string> = {
    "avg_cpu_perc": "Average CPU Percentage",
    "max_cpu_perc": "Max CPU Percentage",
    "max_gpu_usage_perc": "Max GPU Percentage",
    "avg_memory_perc": "Average Memory Percentage",
    "active_time_hrs": "Active Time Hrs",
    "cpu_impact_time_hrs": "CPU Impact Time Hrs",
    "memory_impact_time_hrs": "Memory Impact Time Hrs",
    "available_storage": "Storage Space Utilised"
};

const UserInfokeyMappingUtil: Record<string, string> = {
    "gtm_persona": "GTM Persona",
    "tmx_persona": "TMX Persona",
    "current_device": "Current Device",
    "manager": "Manager",
    "serial_number": "Serial Number",
};

const CurrentUtilDisplay = () => {
    const headers = [
        "avg_cpu_perc",
        "max_cpu_perc",
        "max_gpu_usage_perc",
        "avg_memory_perc",
        "active_time_hrs",
        "cpu_impact_time_hrs",
        "memory_impact_time_hrs",
        "available_storage"
    ];
}
const AssetRecommendationPage: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [currentUtil, setCurrentUtil] = useState<CurrentUtil | null>(null);
    const [currentSysInfo, setCurrentSysInfo] = useState<CurrentSysInfo | null>(null);
    const [recommendations, setRecommendations] = useState<Recommendation[] | null>(null);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [imageURL, setImageURL] = useState<string | null>(null);
    const [empId, setEmployeeId] = useState('');
    const [space, setSpace] = useState<number | null>(null);

    const [reqData, setData] = useState<TelemetryItem[] | null>(null);

    const handleSearch = async () => {
        if (!searchTerm) return;

        setLoading(true);
        setResponseMessage(null);
        setUserInfo(null);
        setCurrentUtil(null);
        setCurrentSysInfo(null);
        setRecommendations(null);

        try {
            const apikey = process.env.REACT_APP_L7_APIKEY;
            const token = await auth.getAccessToken();
            const URL=baseUrl+`/ipss/v1/recommend`;
            const response = await axios.get(URL, {
                params: {
                    user_search: searchTerm
                },
                headers: URL.toLowerCase().includes(".anp2.pcf.dell.com") ? {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs
                } : {
                    'Content-Type': 'application/json',
                    jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
                    apikey: apikey, // For L7 URLs
                }
            });

            const responseData = response.data;

            if (typeof responseData === 'string') {
                setResponseMessage(responseData);
            } else {
                const user_info = responseData.data.userInfo;
                const current_util = responseData.data.utilization;
                const current_sys_info = responseData.data.systemInfoMetaData;
                const recommendations = responseData.data.recommend;
                const employeeId = user_info?.employeeid || null;
                const filteredUserInfo = Object.entries(user_info)
                    .filter(([key]) => key !== 'employeeid')
                    .filter(([key, value]) => value !== "")
                    .reduce((obj, [key, value]) => {
                        const displayKey = key === "Previous laptop" ? "Current Device" : key;
                        obj[displayKey as keyof UserInfo] = value as string;
                        obj[key as keyof UserInfo] = value as string;
                        return obj;
                    }, {} as UserInfo);

                // Filter out unnecessary fields from current_sys_info
                const filteredSysInfo = {
                    ...current_sys_info,
                    financial_year: undefined,
                    tmx_personas: undefined,
                    thunderbolt: undefined,
                    connectivity: undefined
                };

                // Remove undefined fields from filteredSysInfo
                const cleanedSysInfo = Object.entries(filteredSysInfo)
                    .filter(([key, value]) => value !== undefined)
                    .reduce((obj, [key, value]) => {
                        obj[key] = value as string;
                        return obj;
                    }, {} as CurrentSysInfo);

                setUserInfo(filteredUserInfo);
                const storage_size: number = parseInt(cleanedSysInfo["storage"].replace(/\D/g, ''), 10);
                const available_storage: number = Math.floor(parseFloat(current_util["available_storage"].split(' ')[0]));
                const calcDiscUtil: number = storage_size - available_storage;
                setSpace(calcDiscUtil);

                // Calculate the percentage of utilized space
                const utilizedPercentage: number = (calcDiscUtil / storage_size) * 100;
                current_util["available_storage"] = utilizedPercentage.toFixed(2);
                setCurrentUtil(Object.keys(current_util).length > 0 ? current_util : null);
                setCurrentSysInfo(Object.keys(cleanedSysInfo).length > 0 ? cleanedSysInfo : null);
                setRecommendations(recommendations);
            }
        } catch (err) {
            setResponseMessage('Error fetching data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        document.title = 'Asset Inventory | Asset Utilisation and Recommendation';
    }, []);

    const keyMapping = {
        laptop_persona: 'Persona',
        user_type: 'User Type',
        device_type: 'Device Type',
        device_name: 'Device Name',
        processor: 'Processor',
        memory_in_gb: 'Memory in GB',
        storage: 'Storage',
        graphics: 'Graphics',
        display: 'Display',
    };

    const recommendationAttributes = Object.keys(keyMapping);
    const auth = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            // const response = await axios.get('https://trackit-rest-api-automation-g1.anp2.pcf.dell.com/trackitservice/api/Fetch/v1/TelemertyMeasures');
            const token = await auth.getAccessToken();
            const URL = baseUrl + '/ipss/v2/utilisationRules';
            const apikey = process.env.REACT_APP_L7_APIKEY;
            fetch(URL, {
                method: 'GET',
                headers: URL.toLowerCase().includes(".anp2.pcf.dell.com") ? {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs

                } as HeadersInit : {
                    'Content-Type': 'application/json',
                    jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
                    apikey: apikey, // For L7 URLs
                } as HeadersInit,
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data.data)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        console.log("result", reqData);
        // console.log("badge id: ",user.badgeId);
        fetchData();
    }, []);

    console.log("out data", reqData)
    const gpuData = reqData ? reqData.find(item => item.telemetryMeasures === "GPU") : null;
    const cpuData = reqData ? reqData.find(item => item.telemetryMeasures === "CPU") : null;
    const discData = reqData ? reqData.find(item => item.telemetryMeasures === "DISK Utilisation") : null;
    const memoryData = reqData ? reqData.find(item => item.telemetryMeasures === "Memory") : null;
    console.log("discData", discData);
    console.log("cpuData", cpuData);
    // Define the tooltips
    const tooltipMapping = {
        "avg_cpu_perc": cpuData ? ("Low: " + cpuData.lowThreshold + "% " + " High: " + cpuData.highThreshold + "%") : "Threshold loading...",
        "max_cpu_perc": "",
        "max_gpu_usage_perc": gpuData ? ("Low: " + gpuData.lowThreshold + "% " + " High: " + gpuData.highThreshold + "%") : "Threshold loading...",
        "avg_memory_perc": memoryData ? ("Low: " + memoryData.lowThreshold + "% " + " High: " + memoryData.highThreshold + "%") : "Threshold loading...",
        "active_time_hrs": "",
        "cpu_impact_time_hrs": "",
        "memory_impact_time_hrs": "",
        "available_storage": discData ? ("Low: " + discData.lowThreshold + "% " + " High: " + discData.highThreshold + "%") : "Threshold loading...",
    };
    const parseFloatWithDecimals = (value, decimals) => {
        return parseFloat(value).toFixed(decimals);
    }

    const tooltipLow = {
        "avg_cpu_perc": cpuData ? parseFloatWithDecimals(cpuData.lowThreshold, 1) : null,
        "max_cpu_perc": null,
        "max_gpu_usage_perc": gpuData ? parseFloatWithDecimals(gpuData.lowThreshold, 1) : null,
        "avg_memory_perc": memoryData ? parseFloatWithDecimals(memoryData.lowThreshold, 1) : null,
        "active_time_hrs": null,
        "cpu_impact_time_hrs": null,
        "memory_impact_time_hrs": null,
        "available_storage": discData ? parseFloatWithDecimals(discData.lowThreshold, 1) : null,
    };

    const tooltipHigh = {
        "avg_cpu_perc": cpuData ? parseFloatWithDecimals(cpuData.highThreshold, 1) : null,
        "max_cpu_perc": null,
        "max_gpu_usage_perc": gpuData ? parseFloatWithDecimals(gpuData.highThreshold, 1) : null,
        "avg_memory_perc": memoryData ? parseFloatWithDecimals(memoryData.highThreshold, 1) : null,
        "active_time_hrs": null,
        "cpu_impact_time_hrs": null,
        "memory_impact_time_hrs": null,
        "available_storage": discData ? parseFloatWithDecimals(discData.highThreshold, 1) : null,
    };

    return (
        <div className="dds__container-fluid dds__px-0">
            <PageTitle>PC Selector Recommendation</PageTitle>
            <div style={{ padding: '20px', marginTop: '10px', paddingTop: '30px', }}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter email, badge ID, or NTID"
                    style={{
                        padding: '10px',
                        width: '50%',
                        marginRight: '10px',
                        fontSize: '16px',
                    }}
                />
                {/*  */}
                <button
                    onClick={handleSearch}
                    disabled={loading}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        backgroundColor: loading ? '#ccc' : '#0076ce',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        paddingLeft: '20px',
                    }}
                >
                    {loading ? 'Searching...' : 'Search'}
                </button>

                {responseMessage && (
                    <p style={{
                        marginTop: '30px',
                        color: responseMessage.startsWith('Error') ? 'red' : 'black',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }}>
                        {responseMessage}
                    </p>
                )}

                {(userInfo || currentUtil || currentSysInfo) && (
                    <div style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
                        <div
                            style={{
                                padding: '20px',
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                flex: '1',
                                maxWidth: '50%',
                                marginRight: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            {/* User Info */}
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                                {/* <img
                                    width={100}
                                    height={100}
                                    src={imageURL ? imageURL : placeholderImage}
                                    alt={`Profile image of ${userInfo?.["Full Name"]}`}
                                    style={{ borderRadius: "50px", marginLeft: '5px' }}
                                /> */}
                                <div>
                                    <ImageFetcher searchItem={userInfo?.["email"]} />
                                </div>
                                <div style={{ marginTop: '5px', marginLeft: '10px' }}>
                                    <p><strong>Full Name:</strong> {userInfo?.["full_name"]}</p>
                                    <p><strong>Email:</strong> {userInfo?.["email"]}</p>
                                    <p><strong>Job Family:</strong> {userInfo?.["job_family"]}</p>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {Object.entries(userInfo || {}).map(([key, value]) => (
                                    !["full_name", "email", "job_family"].includes(key) && (
                                        <p key={key}>
                                            <strong>{UserInfokeyMappingUtil[key] || key}:</strong> {value}
                                        </p>
                                    )
                                ))}
                            </div>
                        </div>

                        {currentUtil && (
                            <div style={{
                                display: "flex", flexDirection: "row",
                                padding: '20px',
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                flex: '1',
                                maxWidth: '50%',
                                marginRight: '30px',
                                justifyContent: 'space-between',
                                // display: 'flex',
                                // flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <div
                                    style={{

                                    }}
                                >

                                    <h3>Current Utilization</h3>


                                    <div>
                                        {Object.entries(currentUtil).map(([key, value]) => (
                                            <p key={key}>
                                                <strong>
                                                    {/* { value = parseFloat(value).toFixed(1) } */}
                                                    {/* {tooltipHigh[key] = parseFloat(tooltipHigh[key]).toFixed(1)} */}
                                                    {/* {tooltipLow[key] = parseFloat(tooltipLow[key]).toFixed(1)} */}
                                                    {keyMappingUtil[key] || key}
                                                    {/* map through tooltipMapping and then comapre the values of tooltipMappingand currentUtil, check if they are equal, then print the tooltip below */}
                                                    {tooltipMapping[key] && <Tooltip text={tooltipMapping[key]}>
                                                        <div className="dds__icon--alert-info-cir" style={{ fontSize: "1.2rem", marginLeft: "0.1rem", transform: "translateY(0.1rem)", color: "#0076CE" }}></div>
                                                    </Tooltip>} :
                                                </strong>
                                                {/* color: (tooltipMapping[key]) ? ((value > tooltipMapping[key]) ? "red" :((value < tooltipMapping[key]) ? "orange" : "green" ) ) : ""  */}
                                                <div style={{ display: "inline-block", marginLeft: "0.25rem", color: ((tooltipHigh[key] && tooltipLow[key]) ? (parseFloat(value) > parseFloat(tooltipHigh[key]) ? 'red' : parseFloat(value) <= parseFloat(tooltipLow[key]) ? 'orange' : ((parseFloat(value) > parseFloat(tooltipLow[key]) && parseFloat(value) <= parseFloat(tooltipHigh[key])) ? "green" : "")) : "") }}>{value == "nan" ? "N/A" : value + ((key === "cpu_impact_time_hrs" || key === "active_time_hrs" || key === "memory_impact_time_hrs") ? " hrs" : "%")} {(key === "available_storage") ? "( " + space + "GB )" : ""} </div>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <div><Legend /></div>
                            </div>
                        )}
                    </div>
                )}

                {recommendations && (
                    <div
                        style={{
                            marginTop: '20px',
                        }}
                    >
                        <h3>Recommendations</h3>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>#</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Current System</th>
                                    {recommendations.map((_, index) => (
                                        <th key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                            Recommendation {index + 1}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {recommendationAttributes.map((attribute) => (
                                    <tr key={attribute}>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', fontWeight: 'bold' }}>
                                            {keyMapping[attribute]}
                                        </td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>
                                            {attribute === 'device_type'
                                                ? currentSysInfo?.laptop_classification || '-'
                                                : currentSysInfo?.[attribute] || '-'}
                                        </td>
                                        {recommendations.map((recommendation, index) => (
                                            <td
                                                key={`${attribute}-${index}`}
                                                style={{ border: '1px solid #ddd', padding: '8px' }}
                                            >
                                                {attribute === 'device_type'
                                                    ? recommendation.device_type || '-'
                                                    : recommendation[attribute] || '-'}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div >
    );
};

export default AssetRecommendationPage;
