import { useEffect, useRef, useState } from 'react'
import { IconButton } from '../../../../Table/TableStyles'
import { useRegions } from '../../ManageUsersPage/RegionContext'
import SearchUserBox from './InputFields/SearchUserBox'
import useRows from './useRows'
import useSaveData from './useSaveData'
import ModalFooter from './ModalFooter'
import useDropdown from './useDropdown';
import { ModalBody } from '../Styles'
import RoleDropdown from './InputFields/RoleDropdown'
import CountryDropdown from './InputFields/CountryDropdown'
import StockroomDropdown from './InputFields/StockroomDropdown'
import LoadingSpinner from '../../../../Common/LoadingSpinner'
import { useUserInfo } from '../../../../../store/auth/UserInfoContext'
import { Dropdown } from "@dds/components";

const ModalForm = ({ mode, overflow, permissionData, moduleId, handleClose, ...props }) => {
	const { regions } = useRegions();
	console.log("EmployeeDetails", props.employeeDetails)
	const { isSaving, saveData } = useSaveData(mode, handleClose, moduleId)
	//const [isPermissionData, setPermissionData] = useState(permissionData)
	const [totalPermissionData, setTotalPermissionData] = useState<string[]>(permissionData?.map(item => item.id) || []);

	//console.log("permissionData", permissionData, moduleId)
	// track rows
	const { rows, setRows, addRow, deleteRow, rowsLeft } = useRows()

	const {
		handleCountryChange,
		loadData,
		ispreloading,
		getData,
		handleRoleChange,
		checkDropdown,
		disableSearch,
		setDisableSearch,
		searchInput,
		setSearchInput,
		role,
		checkDisabledStockroomDropdown,
		setExtraEmployeeInformation
	} = useDropdown(rows, setRows)
	const disabled = useRef<boolean>(false);
	const userNTID = useUserInfo().getUserInfo().ntId;
	useEffect(() => {

		if (mode === 'edit') {
			console.log("EmployeeDetails", props.employeeDetails, props.accessDetails)
			loadData(props.employeeDetails, props.accessDetails)
			disabled.current = (userNTID + "").toLowerCase() === (props.employeeDetails['ntid'] + "").toLowerCase()
		}
	}, [])

	const divScrollRef = useRef<any>();

	// autoscroll to the newly added row
	useEffect(() => {
		if (rows.operation === "add" && divScrollRef.current)
			divScrollRef.current.scrollIntoView({ behavior: "smooth" });
	}, [rows])

	const saveHandler = () => {
		//console.log("save handler", getData())
		saveData({ mode, ...getData(), selectedItems, totalPermissionData })
	}

	/**
	* Below usestate help to store the exist selected items in the dropdown
	*/
	const [selectedItems, setSelectedItems] = useState<string[]>(props.employeeDetails?.existPermission?.map(item => item.id) || []);

	const handleSelection = (id: string) => {
		setSelectedItems(prevSelectedItems =>
			prevSelectedItems.includes(id)
				? prevSelectedItems.filter(item => item !== id)
				: [...prevSelectedItems, id]
		);
	};

	useEffect(() => {
		const element = document.getElementById("multi-select-list-dropdown");
		Dropdown(element, { selection: "multiple" });
	}, [role])
	return (
		<>
			{/* Modal Body */}
			<ModalBody overflow={overflow}>
				<div id="admin-form"
					className="dds__container-fluid"
					style={{
						width: '99%'
					}}>
					<div className='dds__row'>

						<div className="dds__col-1">
							<SearchUserBox mode={mode}
								searchInput={searchInput}
								setSearchInput={setSearchInput}
								setExtraEmployeeInformation={setExtraEmployeeInformation}
								disableSearch={disableSearch}
								setDisableSearch={setDisableSearch} />
						</div>
						<div className="dds__col-1">
							{(mode === 'new' || role.rolename) && <RoleDropdown
								handleRoleChange={handleRoleChange}
								checkDropdown={checkDropdown}
								role={role}
								disabled={disabled.current}
							/>}

						</div>
					</div>
					{role.rolename === "admin" && permissionData && (
						<div style={{ marginTop: "10px" }}>
							<div className="dds__dropdown" data-dds="dropdown" data-select-all="false" id="multi-select-list-dropdown" data-selection="multiple" data-select-all-label="Select all">
								<div className="dds__dropdown__input-container">
									<label id="dropdown-label-120844768" htmlFor="dropdown-control-120844768" className="dds__label">Permission</label>
									<div className="dds__dropdown__input-wrapper" aria-haspopup="listbox" aria-controls="multi-select-list-dropdown-popup">
										<input
											id="multi-select-list-dropdown-input"
											name="multi-select-list-dropdown-name"
											type="text"
											role="combobox"
											className="dds__dropdown__input-field"
											aria-labelledby="multi-select-list-dropdown-label multi-select-list-dropdown-helper"
											aria-expanded="false"
											aria-controls="multi-select-list-dropdown-list"
										/>

										<i className="dds__icon dds__icon--alert-notice dds__dropdown__feedback__icon" aria-hidden="true"></i>
									</div>
								</div>
								<div id="multi-select-list-dropdown-popup" className="dds__dropdown__popup dds__dropdown__popup--hidden" role="presentation" tabIndex={-1}>
									<ul className="dds__dropdown__list" role="listbox" tabIndex={-1} id="multi-select-list-dropdown-list">
										{permissionData.map(item => (
											<li className="dds__dropdown__item" role="none" key={item.id}>
												<button type="button" className="dds__dropdown__item-option" role="option" data-selected={selectedItems.includes(item.id) ? true : false} data-value={item.id} tabIndex={-1}
													onClick={() => handleSelection(item.id)} >
													<span className="dds__dropdown__item-label">{item.name}</span>
												</button>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>


					)}
					{/* Todo: Temporary commented the following line. Need to uncomment later. */}
					{ispreloading && <div className="dds__mt-5"><LoadingSpinner size='md' /></div>}
					{!ispreloading && [...Array(rows.value)].map((e, rowIndex) => <div className='dds__row dds__mt-3' key={rowIndex}>
						<div className='dds__col--md-5'>
							<CountryDropdown
								handleCountryChange={handleCountryChange}
								regions={regions}
								checkDropdown={checkDropdown}
								rowIndex={rowIndex}
							/>
						</div>

						<div className="dds__col--md-5">
							<StockroomDropdown regions={regions}
								checkDropdown={checkDropdown}
								rowIndex={rowIndex}
								inputDisabled={checkDisabledStockroomDropdown(rowIndex)}
							/>

						</div>
						{(rowIndex + 1 === rows.value) ?
							((rowsLeft > 0 && (rowIndex + 1 < regions.length)) ? <div className='dds__col--md-2 dds__pt-4 dds__mt-2'>
								<IconButton
									className="dds__button dds__button--secondary"
									onClick={(e) => {
										e.preventDefault()
										addRow(rowIndex)
									}}
									ref={divScrollRef}
								>
									<i style={{ fontSize: '1.8rem' }} className="dds__icon dds__icon--add-cir" aria-hidden="true" />
								</IconButton>
							</div> : "") :
							<div className='dds__col--md-2 dds__pt-4 dds__mt-2'>
								<IconButton
									className="dds__button dds__button--secondary"
									onClick={(e) => {
										e.preventDefault();
										deleteRow(rowIndex)
									}}
								>
									<i style={{ fontSize: '1.8rem' }} className="dds__icon dds__icon--trash" aria-hidden="true" />
								</IconButton>
							</div>
						}

					</div>
					)}

				</div>
			</ModalBody>
			{/* Modal Footer */}
			<ModalFooter
				saveHandler={saveHandler}
				disableSearch={disableSearch && role.roleid}
				handleClose={handleClose}
				isSaving={isSaving} />

		</>
	)
}

export default ModalForm